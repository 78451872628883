import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Button, Card, CardBody, CardFooter, Collapse } from 'reactstrap';
import defaultAvatar from '../../../assets/img/default-avatar.png';

class SupervisorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false
    };
  }

  componentWillReceiveProps() {
    this.setState(state => ({ collapse: false }));
  }

  onCollapse() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    let { collapse } = this.state;
    let { userData } = this.props;

    return (
      <Card>
        <CardBody>
          <div className={'d-flex'}>
            <div className={'w-100 px-2 text-break'}>
              <h5>{userData.displayName}</h5>
              {userData.email ? (
                <p className="m-0">
                  <a
                    href={`mailto:${userData.email}?Subject=Placeshaker`}
                    target="_top"
                  >
                    {userData.email}
                  </a>
                </p>
              ) : null}
              {userData.address ? (
                <p className="m-0">{userData.address}</p>
              ) : null}
            </div>
            <div className={'flex-shrink-1 px-2'}>
              <div
                className="user-avatar user-avatar-100 img-raised"
                style={{
                  backgroundImage: `url('${
                    userData.photoURL ? userData.photoURL : defaultAvatar
                  }')`
                }}
              />
            </div>
          </div>
          <Collapse className="mt-3" isOpen={collapse}>
            <strong>
              <Trans>Phones</Trans>
            </strong>
            <div className="d-flex flex-wrap flex-md-nowrap justify-content-around">
              {userData.phone && userData.phone.mobile ? (
                <div className="p-2">
                  {userData.phone.mobile} (<Trans>Mobile</Trans>)
                </div>
              ) : null}
              {userData.phone && userData.phone.landline ? (
                <div className="p-2">
                  {userData.phone.landline} (<Trans>Landline</Trans>)
                </div>
              ) : null}
              {userData.phone && userData.phone.work ? (
                <div className="p-2">
                  {userData.phone.work} (<Trans>Work</Trans>)
                </div>
              ) : null}
            </div>
          </Collapse>
        </CardBody>
        {userData.phone &&
        (userData.phone.mobile ||
          userData.phone.landline ||
          userData.phone.work) ? (
          <CardFooter>
            <hr className="mt-0" />
            <Button
              className="btn-neutral"
              color="default"
              size="sm"
              onClick={() => this.onCollapse()}
            >
              {collapse
                ? [
                    <i key="icon" className="fa fa-minus mr-1" />,
                    <Trans key="text">See Less</Trans>
                  ]
                : [
                    <i key="icon" className="fa fa-plus mr-1" />,
                    <Trans key="text">See More</Trans>
                  ]}
            </Button>
          </CardFooter>
        ) : null}
      </Card>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(SupervisorCard)
);
