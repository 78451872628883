import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import { PaginationCustom, PanelHeader, Spinner } from '../../../components';
import { supervisorsContacts } from '../../../helpers/actions/pros';
import SupervisorCard from './SupervisorCard';

class Supervisors extends Component {
  state = {
    loading: true,
    data: [],
    count: 0,
    page: 1,
    limit: 18,
    search: ''
  };

  load() {
    this.setState({ loading: true });

    let { page, limit, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(supervisorsContacts({ limit, offset, search }))
      .then(({ data, meta }) =>
        this.setState({ data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  filter(search) {
    this.setState({ search, page: 1 }, () => this.load());
  }

  render() {
    let { loading, data, page, limit, count } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col className={'text-left'} xs={6} md={6}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event => this.filter(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
          {data.length > 0 ? (
            <div>
              <Row>
                {data.map((u, key) => (
                  <Col key={key} xs={12} md={6} lg={4}>
                    <SupervisorCard userData={u} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No supervisors found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default connect(state => state)(
  translate('translations-fr')(Supervisors)
);
